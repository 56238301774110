<template >
    <div>
        <nav class="navbar sticky-top navbar-expand-lg navbar-light" id="HeaderNav">
          <div class="container-xl">
            <router-link to="/" ><a class="navbar-brand">
              <img src="/img/logo-brand.png" alt="رایه الحق" width="60" height="60">
            </a></router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleContent"
                    aria-controls="navbarToggleContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarToggleContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <router-link class="topMenu active" to="/"><a class="nav-link">{{ $t('index') }}<span class="sr-only">(current)</span></a></router-link>
                </li>
                <li class="nav-item mr-lg-3">
                  <router-link class="topMenu" to="/VideoList"><a class="nav-link">{{ $t('videos_list') }}</a></router-link>
                </li>
                <li class="nav-item mr-lg-3">
                  <router-link class="topMenu" to="/bookmarks"><a class="nav-link">{{ $t('favourites')}}</a></router-link>
                </li>
                <li class="nav-item mr-lg-3">
                  <router-link class="topMenu" to="/commentsList"><a class="nav-link"> {{$t('comments')}}</a></router-link>
                </li>

                <li class="nav-item mr-lg-3">
                  <router-link class="topMenu" to="/AboutUs"><a class="nav-link">{{ $t('about_us') }}</a></router-link>
                </li>

                <li class="nav-item mr-lg-3">
                  <router-link class="topMenu" to="/LogIn"><a class="nav-link" >{{ $t('login') }}</a></router-link>
                </li>
                <li class="nav-item mr-lg-3">
                  <router-link class="topMenu" to="/Register"><a class="nav-link border border-dark rounded"> {{ $t('register') }}</a></router-link>
                </li>
              </ul>

              <ul class="navbar-nav mr-auto">
                <li class="nav-item dropdown ml-3">
                  <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false">
                    <img src="/img/profile-pic.png" alt="profile-pic" height="30"/>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="profileDropdown">
                    <router-link to="/profile"><a class="dropdown-item" href="">{{$t('profile_update')}}</a></router-link>
                    <a class="dropdown-item" href="" @click="signOutAdmin" >{{$t('logout')}}</a>
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{dropdownTitle}}</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" v-for="entry in languages"  href="#" @click="changeLocale(entry.language)" :key="entry.language">{{entry.title}}</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
    </div>

</template>
<script>

export default {
  name: "Header",
  data() {
    return {
      languages:
        [
          {  language: 'ar', title: 'arabic' },
          {  language: 'fa', title: 'persian' }
        ],
      dropdownTitle: this.$i18n.locale
    };
  },
  mounted() {
    window.jQuery('.topMenu').on('click', function(){
      window.jQuery('.topMenu').removeClass('active');
      window.jQuery(this).addClass('active');
    });
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.dropdownTitle = locale;
    }, signOutAdmin() {
      window.$cookies.remove('auth');
      window.location.href = '/';
    }
  }
}
</script>
