<template>
  <div class="content">
    <div class="container-xl">

      <div class="row row-cols-1 reviews">
        <div class="review-reg">
          <input class="btn float-left review-reg-btn col-12 col-sm-4 col-md-3 col-lg-2" type="button" :value="$t('write_comment')" data-toggle="modal" data-target="#regReviewModal">
        </div>
        <div class="col review-card" v-for="offer in get_comments" v-bind:key="offer.id" >
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">{{offer.username}}</h5>
              <div class="card-date">
                  <span type="date">
                    {{offer.created_at.substring(0,10)}}
                  </span>
              </div>
              <p class="card-text">
                {{offer.offer}}
              </p>
            </div>
            <div class="card-footer">
             {{offer.answer}}
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="modal fade" id="regReviewModal" tabindex="-1" aria-labelledby="regReviewModalLabel" aria-hidden="true">
      <div id="model" class="modal-dialog modal-dialog-centered modal-lg register-modal-dialog" style=" width: 300% ; height: fit-content">
        <div class="modal-content" >
          <div class="modal-body register-modal-body" style="height: fit-content">
              <button type="button" class="close float-left ml-0 mr-auto" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" class="text-summersky">&times;</span>
              </button>
            <br>
            <form>
              <div class="form-group">
                <label for="exampleFormControlInput1">{{$t('username')}}</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" :placeholder="$t('username')" v-model="username">
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">{{$t('comment_text')}}</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model = 'ticket'></textarea>
              </div>
              <button type="button" class="btn btn-primary" data-dismiss="modal" @click="SendCommentToServer">{{$t('send_comment')}}</button>
            </form>

          </div>

        </div>
      </div>
    </div>
    <div style="height: 400px; width: 100%; text-align: center;margin-top: -200px" id="loadingContainer">
      <vue-element-loading
          id="loading"
          background-color="transparent"
          opacity="80%"
          spinner="ring"
          :text="$t('loading')" color="black" size="100" style="margin-top: 20%"
          v-model="fullpage"
      />
    </div>
  </div>

</template>

<script>
import VueElementLoading from 'vue-element-loading'
export default {
  name: "ComentsList",
  data(){
    return{
       username : '',
       user_id : '',
       ticket :'',
       check : {},
       get_comments : [],
       fullpage:''
    }
  }, components: {
    VueElementLoading,
  },mounted(){
    window.jQuery('#loading').show()
  },created() {
    this.check = this.$cookies.get('user');
    if (this.check != null)
    {
      this.username = this.check.name;
      this.user_id = this.check.id;
    }
    this.GetCommentFromServer();
  },methods: {
    SendCommentToServer() {
      
      window.axios.post('https://panel.raytalhaq.app/api/addTicket', {
        id: this.user_id,
        username: this.username,
        ticket: this.ticket,
      }).then((response) => {
        console.log(response);
        this.$swal({
          title: this.$t('success_comment'),
          confirmButtonText: this.$t('close'),
          timer: 3000,
        })
      }).catch((error) => {
        console.log(error);
        this.$swal({
          title: this.$t('error_comment'),
          confirmButtonText: this.$t('close'),

        })
        }
      )
    },GetCommentFromServer() {
      window.axios.get('https://panel.raytalhaq.app/api/tickets').then((response) => {
        this.get_comments = response.data.data;
        window.jQuery('#loading').hide();
        window.jQuery('#loadingContainer').hide();
        window.jQuery('#moreLoading').hide();

      })


    }
  }
}
</script>

<style scoped>

</style>
