<template>
  <div role="main">
    <div class="content">
      <div class="container-xl">
        <div class="row product-image-box pb-3">
          <div class="col-12 col-sm-3">
            <img :src="image_link +'/'+ showBook.id +'/'+ showBook.image" alt="product image" class="img-fluid"/>
          </div>
          <div class="col-12 col-sm-9">
            <div class="d-block d-sm-none mt-3"></div>
            <ul class="no-list-style list-group list-group-horizontal pr-0 mb-4">
              <li>
                <a href="#" :title="$t('bookmark')" v-show="bookmarkIcon===1" class="text-summersky"
                   @click="BookMarkBook">
                  <i class="fas fa-bookmark"></i>
                  {{ $t('bookmark') }}
                </a>
                <a href="#" id="bookmark_fill" :title="$t('bookmark')" v-show="bookmarkIcon===0" class="text-summersky"
                   @click="BookMarkBook">
                  <i class="las la-bookmark"></i>
                  {{ $t('Un_bookmark') }}
                </a>
              </li>
            </ul>
            <h1 class="book-name mb-4" v-if="$i18n.locale === 'ar'">{{ showBook.ar_name }}</h1>
            <h1 class="book-name mb-4" v-if="$i18n.locale === 'fa'">{{ showBook.fa_name }}</h1>
            <h2 class="writer-name mb-3">{{ showBook.author }}</h2>
            <br>
            <br>
            <br>
            <div id="product-detail-tabs-box">
              <ul class="nav nav-tabs pl-0" id="product-detail-tabs" role="tablist">
                <li v-if="showBook.ar_pdf !== 0 || showBook.fa_pdf !== 0" class="nav-item" role="presentation"
                    @click="changeFormatBook('pdf')">
                  <a class="nav-link link-book active"
                     id="book-tab" data-toggle="tab" href="#book" role="tab"
                     aria-controls="book" aria-selected="true">
                    <span v-if="showBook.fa_pdf == 1 && showBook.ar_pdf == 0">
                      <i class="las la-book ml-3" style="font-size: 14px ; font-style: unset">fa</i>
                    </span>
                    <span v-if="showBook.fa_pdf == 0 && showBook.ar_pdf == 1">
                      <i class="las la-book ml-3" style="font-size: 14px ; font-style: unset"> Ar</i>
                    </span>
                    <span v-if="showBook.fa_pdf == 1 && showBook.ar_pdf == 1">
                      <i class="las la-book ml-3" style="font-size: 14px ; font-style: unset"> Ar Fa</i>
                    </span>
                  </a>
                </li>
                <li v-if="showBook.ar_mp4 !== 0 || showBook.fa_mp4 !== 0" class="nav-item" role="presentation"
                    @click="changeFormatBook('mp4')">
                  <a class="nav-link link-video" id="video-tab"
                     data-toggle="tab" href="#video" role="tab"
                     aria-controls="video" aria-selected="false">
                    <span v-if="showBook.fa_mp4 == 1 && showBook.ar_mp4 == 0">
                      <i class="lar la-play-circle ml-3" style="font-size: 14px ; font-style: unset">Fa</i>
                    </span>
                    <span v-if="showBook.fa_mp4 == 0 && showBook.ar_mp4 == 1">
                      <i class="lar la-play-circle ml-3" style="font-size: 14px ; font-style: unset">Ar</i>
                    </span>
                    <span v-if="showBook.fa_mp4 == 1 && showBook.ar_mp4 == 1">
                      <i class="lar la-play-circle ml-3" style="font-size: 14px ; font-style: unset"> Ar Fa</i>
                    </span>
                  </a>
                </li>
                <li v-if="showBook.ar_mp3 !== 0 || showBook.fa_mp3 !== 0" class="nav-item" role="presentation"
                    @click="changeFormatBook('mp3')">
                  <a class="nav-link link-sound" id="sound-tab"
                     data-toggle="tab" href="#sound" role="tab"
                     aria-controls="sound" aria-selected="false">
                    <span v-if="showBook.fa_mp3 == 1 && showBook.ar_mp3 == 0">
                      <i class="las la-headphones ml-3" style="font-size: 14px ; font-style: unset">FA</i>
                    </span>
                    <span v-if="showBook.fa_mp3 == 0 && showBook.ar_mp3 == 1">
                      <i class="las la-headphones ml-3" style="font-size: 14px ; font-style: unset">Ar</i>
                    </span>
                    <span v-if="showBook.fa_mp3 == 1 && showBook.ar_mp3 == 1">
                      <i class="las la-headphones ml-3" style="font-size: 14px ; font-style: unset">Fa Ar</i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row pt-5 product-detail-box">
          <div class="tab-content col" id="product-detail-tabs-content">
            <div class="tab-pane fade show active" id="book" role="tabpanel" aria-labelledby="book-tab"
                 v-for="season in seasonsFilePdf" v-bind:key="season.id">
              <div class="mx-auto col-12 col-sm-3 text-center">
                <p>
                  {{ $t('downloadPdf_text') }}
                </p>
                <a style="margin-bottom: 10%" class="bg-summersky w-100 d-block text-white py-2" download=""
                   :href="link_season+'/'+currentSeasonId+'/'+season.name" :title="$t('downloadBook_text') ">
                  <i class="las la-download"></i>
                  {{ season.name }}
                </a>
              </div>
            </div>

            <div class="tab-pane fade" id="video" role="tabpanel" aria-labelledby="video-tab">
              <div class="row">
                <div class="col-md-4" v-for="season in seasonsFileMp4" v-bind:key="season.id">
                  <div class="card mb-2" style="align-items: center">
                    <div class="card-video-image-wrapper">
                      <video width="300" height="300" controls class="card-video-image-wrapper">
                        <source :src="link_season+'/'+currentSeasonId+'/'+season.name" type="video/mp4">
                        <source :src="link_season+'/'+currentSeasonId+'/'+season.name" type="video/ogg">
                        {{ $t('browser_does_not_support_video') }}
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="sound" role="tabpanel" aria-labelledby="sound-tab">
              <div class="pb-5 row no-gutters">
                <div class="col-1">
                  <a href="#" data-toggle="modal" data-target="#soundplaylistModal" class="soundplaylist">
                    <i class="las la-list"></i>
                  </a>
                </div>
                <div class="col-11">
                  <audio id="player" controls class="form-control" style="background-color: unset" autoplay>
                    <source id="playerSrc" src="" type="audio/mp3">
                  </audio>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="book-detail-tabs" role="tablist">
              <li class="nav-item col" role="presentation">
                <a class="nav-link active" id="summery-tab" data-toggle="tab" href="#summery" role="tab"
                   aria-controls="summery" aria-selected="true">
                  {{ $t('summery') }}
                </a>
              </li>
              <li class="nav-item col" role="presentation">
                <a class="nav-link" id="sessions-tab" data-toggle="tab" href="#sessions" role="tab"
                   aria-controls="sessions" aria-selected="false">
                  {{ $t('seasons') }}
                </a>
              </li>
              <li class="nav-item col" role="presentation">
                <a class="nav-link" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info"
                   aria-selected="false">
                  {{ $t('attributes') }}
                </a>
              </li>
            </ul>
            <div class="tab-content" id="book-detail-tabs-content">
              <div class="tab-pane fade show active py-3 px-3" id="summery" role="tabpanel"
                   aria-labelledby="summery-tab">
                <div class="summery-content">
                  <p v-if="$i18n.locale === 'ar'" v-html="showBook.ar_summary">
                  <p v-if="$i18n.locale === 'fa'" v-html="showBook.fa_summary">
                  </p>
                </div>
              </div>
              <div class="tab-pane fade" id="sessions" role="tabpanel" aria-labelledby="sessions-tab">
                <table class="table">
                  <tbody id="seasonBody">
                  <tr v-for="(season, index) in seasonOfThisType" @click="changeSeason(index, season.id)"
                      v-bind:key="season.id" :id="'tr_'+season.id" v-bind:class="{ 'selected-background-tr': index===0 }">
                    <td>{{ $t('season') }} {{ index + 1 }}</td>
                    <td v-if="season.count_pages != 0"> {{ season.count_pages }} {{ $t('page') }}</td>
                    <td v-if="season.minute != 0"> {{ season.minute }} {{ $t('minute') }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td>{{ $t('category') }}</td>
                    <td v-if="$i18n.locale === 'ar'">{{ category_ar_name }}</td>
                    <td v-if="$i18n.locale === 'fa'">{{ category_fa_name }}</td>
                  </tr>
                  <tr>
                    <td v-if="typeOfBook==='pdf'">{{ $t('page') }}</td>
                    <td v-else>{{ $t('time') }}</td>

                    <td v-if="typeOfBook==='pdf'"> {{ this.one_file_minOrPage }} {{ $t('page') }}</td>
                    <td v-else> {{ this.one_file_minOrPage }} {{ $t('minute') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('author') }}</td>
                    <td> {{ this.showBook['author'] }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('speaker') }}</td>
                    <td> {{ this.showBook['speaker'] }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('one_file_size') }}</td>
                    <td> {{ this.one_file_size }} {{ $t('Mb') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('file_size') }}</td>
                    <td> {{ this.size }} {{ $t('Mb') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('date') }}</td>
                    <td> {{ this.created_at }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div style="height: 400px; width: 100%; text-align: center;margin-top: -200px" id="loadingContainer">
            <vue-element-loading
                id="loading"
                background-color="transparent"
                opacity="80%"
                spinner="ring"
                :text="$t('loading')" color="black" size="100" style="margin-top: 20%"
                v-model="fullPage"
            />
          </div>
          <div class="modal fade" id="soundplaylistModal" tabindex="-1" aria-labelledby="soundplaylistModalLabel"
               aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm1 register-modal-dialog">
              <div class="modal-content">
                <div class="modal-header text-left">
                  <button type="button" class="close float-left ml-0 mr-auto" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="text-summersky">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                  </p>
                  <table class="table table-borderless">
                    <tbody>
                    <tr v-for="(season,index) in seasonsFileMp3" @click="playSound(season.name)" v-bind:key="season.id">
                      <a data-dismiss="modal" class="my-cursor">
                        <td class="text-summersky">{{ $t('audio') }} {{ index + 1 }}</td>
                      </a>
                      <td class="text-left my-cursor">
                        {{ $t('play_audio') }}
                      </td>
                      <!--<td class="text-left">{{ season.minute }} {{ $t('minute') }}</td>-->
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
//import {apiUrl} from '../../../vue.config.js';

export default {
  name: "productShow",
  data() {
    return {
      showBook: [],
      image_link: '',
      seasons: [],
      link_season: '',
      seasonsFilePdf: [],
      seasonsFileMp4: [],
      seasonsFileMp3: [],
      currentSeasonId: 0,
      File_type: [],
      bookIds: [],
      api_link: "https://panel.raytalhaq.app/api/",
      downLink: "",
      typeOfBook: 'pdf',
      seasonOfThisType: [],
      langBook: this.$i18n.locale,
      season_id: '',
      currSoundLink: "",
      model: true,
      length_File: '',
      fullPage: true,
      bookmark_icon: false,
      unbookmark_icon: true,
      category_ar_name: "",
      category_fa_name: "",
      parchamhagh_bookmark: this.$cookies.get("parchamhagh_bookmark"),
      bookmarkIcon: 0,
      size: 0,
      one_file_size: 0,
      one_file_minOrPage: 0,
      params_id: parseInt(this.$route.params.id),
      created_at: ''
    }
  }, components: {
    VueElementLoading,
  }, mounted() {
    window.jQuery('#loading').show();
    window.jQuery('#loadingContainer').show();
    this.GetbookFromServer();
    if (this.parchamhagh_bookmark === null) {
      this.parchamhagh_bookmark = [];
    } else {
      this.parchamhagh_bookmark = JSON.parse(this.parchamhagh_bookmark);
    }
    if (window.jQuery.inArray(this.params_id, this.parchamhagh_bookmark) === -1) {
      this.bookmarkIcon = 0;
    } else {
      this.bookmarkIcon = 1;
    }
    this.parchamhagh_bookmark = JSON.stringify(this.parchamhagh_bookmark);
  },
  methods: {
    playSound(seasonName) {
      window.jQuery("#playerSrc").attr("src", this.link_season + '/' + this.currentSeasonId + '/' + seasonName);
      let audio = window.jQuery("#player");
      audio[0].pause();
      audio[0].load();
      audio[0].play();
    },
    GetbookFromServer() {
      window.axios.get(this.api_link + 'books' + '/' + this.params_id)
          .then((response) => {
            let firstType = 'pdf';
            this.showBook = response.data.book;
            this.created_at = this.showBook['created_at'].slice(0, 10);
            let fileSize = (this.showBook.total_size_pdf / 1048576);
            this.size = fileSize.toFixed(2);
            this.image_link = response.data.book_img_path;
            this.seasons = response.data.season;
            this.link_season = response.data.season_file_path;
            this.category_ar_name = this.showBook.category.ar_name;
            this.category_fa_name = this.showBook.category.fa_name;
            if (this.showBook.ar_pdf === 1 || this.showBook.fa_pdf === 1)
              firstType = 'pdf';
            else if (this.showBook.ar_mp3 === 1 || this.showBook.fa_mp3 === 1)
              firstType = 'mp3';
            else if (this.showBook.ar_mp4 === 1 || this.showBook.fa_mp4 === 1)
              firstType = 'mp4';
            window.jQuery('#loading').show();
            window.jQuery('#loadingContainer').hide();
            this.setSeasonForFirstTime(firstType);
          });
    },
    changeSeason(index, seasonId) {
      this.currentSeasonId = seasonId;
      let files = this.seasonOfThisType[index]['files'];
      let size = this.seasonOfThisType[index]['size'];
      size = size / 1048576;
      this.one_file_size = size.toFixed(2);
      this.length_File = files.length;
      this.seasonsFilePdf = [];
      this.seasonsFileMp3 = [];
      this.seasonsFileMp4 = [];
      if (this.typeOfBook === 'pdf') {
        this.one_file_minOrPage = this.seasonOfThisType[index]['count_pages'];
        for (let i in files) {
          let arr = {'name': files[i]};
          this.seasonsFilePdf.push(arr);
        }
      }
      if (this.typeOfBook === 'mp3') {
        this.one_file_minOrPage = this.seasonOfThisType[index]['minute'];
        for (let k in files) {
          let arr = {'name': files[k]};
          this.seasonsFileMp3.push(arr);
        }
      }
      if (this.typeOfBook === 'mp4') {
        this.one_file_minOrPage = this.seasonOfThisType[index]['minute'];
        for (let j in files) {
          let arr = {'name': files[j]};
          this.seasonsFileMp4.push(arr);
        }
        if( typeof window.jQuery("video")[0] !== 'undefined' )
          window.jQuery("video")[0].load();
      }

      window.jQuery("#seasonBody").each(function () {
        window.jQuery("tr").removeClass('selected-background-tr');
        window.jQuery("#tr_"+seasonId).addClass('selected-background-tr');
      });
    },
    BookMarkBook() {
      if (this.parchamhagh_bookmark === null) {
        this.parchamhagh_bookmark = [];
      } else {
        this.parchamhagh_bookmark = JSON.parse(this.parchamhagh_bookmark);
      }

      if (window.jQuery.inArray(this.params_id, this.parchamhagh_bookmark) === -1) {
        //nabood
        this.parchamhagh_bookmark.push(this.params_id);
        this.bookmarkIcon = 1;
      } else {
        //bood
        let index = this.parchamhagh_bookmark.indexOf(this.params_id);
        if (index !== -1) {
          this.parchamhagh_bookmark.splice(index, 1);
          this.bookmarkIcon = 0;
        }
      }
      this.parchamhagh_bookmark = JSON.stringify(this.parchamhagh_bookmark);
      this.$cookies.set("parchamhagh_bookmark", this.parchamhagh_bookmark, Infinity);
    },
    changeFormatBook(type) {
      let audio = window.jQuery("#player");
      audio[0].pause();
      audio.hide();
      this.typeOfBook = type;
      /*Lang*/
      if (this.typeOfBook === 'pdf') {
        if (this.showBook.ar_pdf === 1 && this.showBook.fa_pdf === 1) {
          this.selectLang();
        } else if (this.showBook.ar_pdf === 1 && this.showBook.fa_pdf === 0) {
          this.langBook = "ar";
        } else if (this.showBook.ar_pdf === 0 && this.showBook.fa_pdf === 1) {
          this.langBook = "fa";
        }
        this.size = this.showBook.total_size_pdf / 1048576;
      } else if (this.typeOfBook === 'mp3') {
        audio.show();
        if (this.showBook.ar_mp3 === 1 && this.showBook.fa_mp3 === 1) {
          this.selectLang();
        } else if (this.showBook.ar_mp3 === 1 && this.showBook.fa_mp3 === 0) {
          this.langBook = "ar";
        } else if (this.showBook.ar_mp3 === 0 && this.showBook.fa_mp3 === 1) {
          this.langBook = "fa";
        }
        this.size = this.showBook.total_size_mp3 / 1048576;
      } else {
        if (this.showBook.ar_mp4 === 1 && this.showBook.fa_mp4 === 1) {
          this.selectLang();
        } else if (this.showBook.ar_mp4 === 1 && this.showBook.fa_mp4 === 0) {
          this.langBook = "ar";
        } else if (this.showBook.ar_mp4 === 0 && this.showBook.fa_mp4 === 1) {
          this.langBook = "fa";
        }
        this.size = this.showBook.total_size_mp4 / 1048576;
      }
      this.size = this.size.toFixed(2);
      /*Lang*/

      this.getSeasonOfType();
    },
    selectLang() {
      this.$swal({
        title: this.$t('select_lang'),
        confirmButtonText: this.$t('persian'),
        cancelButtonText: this.$t('arabic'),
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.langBook = "fa";
          this.getSeasonOfType();
        } else {
          this.langBook = "ar";
          this.getSeasonOfType();
        }
      });
    },
    getSeasonOfType() {
      this.seasonOfThisType = [];
      let seasonId = 0;
      for (let s in this.seasons) {
        if (this.seasons[s].files_type === this.typeOfBook) {
          if (this.langBook === 'fa') {
            if (this.seasons[s].fa_title !== null) {
              this.seasonOfThisType.push(this.seasons[s]);
              if (this.seasonOfThisType.length === 1)
                seasonId = this.seasons[s].id;
            }
          } else {
            if (this.seasons[s].ar_title !== null) {
              this.seasonOfThisType.push(this.seasons[s]);
              if (this.seasonOfThisType.length === 1)
                seasonId = this.seasons[s].id;
            }
          }
        }
      }
      if (seasonId > 0) {
        this.changeSeason(0, seasonId);
      }
    },
    setSeasonForFirstTime(type) {
      this.typeOfBook = type;
      /*Lang*/
      if (this.typeOfBook === 'pdf') {
        if (this.showBook.ar_pdf === 1 && this.showBook.fa_pdf === 1) {
          this.langBook = this.$i18n.locale;
        } else if (this.showBook.ar_pdf === 1 && this.showBook.fa_pdf === 0) {
          this.langBook = "ar";
        } else if (this.showBook.ar_pdf === 0 && this.showBook.fa_pdf === 1) {
          this.langBook = "fa";
        }
      } else if (this.typeOfBook === 'mp3') {
        if (this.showBook.ar_mp3 === 1 && this.showBook.fa_mp3 === 1) {
          this.langBook = this.$i18n.locale;
        } else if (this.showBook.ar_mp3 === 1 && this.showBook.fa_mp3 === 0) {
          this.langBook = "ar";
        } else if (this.showBook.ar_mp3 === 0 && this.showBook.fa_mp3 === 1) {
          this.langBook = "fa";
        }
      } else {
        if (this.showBook.ar_mp4 === 1 && this.showBook.fa_mp4 === 1) {
          this.langBook = this.$i18n.locale;
        } else if (this.showBook.ar_mp4 === 1 && this.showBook.fa_mp4 === 0) {
          this.langBook = "ar";
        } else if (this.showBook.ar_mp4 === 0 && this.showBook.fa_mp4 === 1) {
          this.langBook = "fa";
        }
      }
      /*Lang*/
      this.getSeasonOfType();
    }
  }
}
</script>
