<template>
  <div class="content">
    <div class="container-xl">
      <div class="videos-wrapper vGrid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
          <div v-for="showVideo in showVideos" class="col" v-bind:key="showVideo.id">
            <div class="card">
              <div class="card-video-image-wrapper">

                <a href="#" :title="$t('showVideo_text')" @click="showModalVideo(showVideo.iframe)">
                  <img width="350" height="200" :src="image_link +'/'+ showVideo.id +'/'+ showVideo.image"
                       class="card-video-image-wrapper">
                </a>
              </div>
              <div class="card-body">
                <h5 v-if="$i18n.locale === 'ar'" class="card-title">
                  <a href="#" :title="$t('showVideo_text')">
                    {{ showVideo.ar_title }}
                  </a>
                </h5>
                <h5 v-if="$i18n.locale === 'fa'" class="card-title">
                  <a href="#" :title="$t('showVideo_text')">
                    {{ showVideo.fa_title }}
                  </a>
                </h5>
                <p v-if="$i18n.locale === 'ar'" class="card-text">
                  {{ showVideo.short_ar_desc }}
                </p>
                <p v-if="$i18n.locale === 'fa'" class="card-text">
                  {{ showVideo.short_fa_desc }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 400px; width: 100%; text-align: center;margin-top: -200px" id="loadingContainer">
          <vue-element-loading
                id="loading"
                background-color="transparent"
                opacity="80%"
                spinner="ring"
                :text="$t('loading')" color="black" size="100" style="margin-top: 20%"
                v-model="fullPage"
          />
        </div>
      </div>
      <a v-if="this.next_page != null" id="moreLoading" @click.prevent="GetMoreVideosFromServer" style="margin-top: 1%"
          class="btn btn-outline-secondary">{{ $t('more') }}</a>
    </div>
    <div class="modal fade" id="listModal" tabindex="-1" aria-labelledby="listModalLabel" aria-hidden="true"
         @click.self="closeIframe">
      <div class="modal-dialog modal-dialog-centered modal-lg register-modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-left">
            <button type="button" class="close float-left ml-0 mr-auto" data-dismiss="modal" aria-label="Close"
                    @click="closeIframe">
              <span aria-hidden="true" class="text-summersky">&times;</span>
            </button>
          </div>
          <div class="modal-body ">
            <div v-html="currentIframe" style=""></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueElementLoading from 'vue-element-loading'
//import {apiUrl} from '../../../vue.config.js';

export default {
  name: "VideoList",
  data() {
    return {
      showVideos: {},
      image_link: '',
      next_page: null,
      isLoading: false,
      fullPage: true,
      currentIframe: "",
      api_link: 'https://panel.raytalhaq.app/api/'
    }
  }, components: {
    VueElementLoading,
  }, mounted() {

    window.jQuery('#loading').show();
    window.jQuery('#loadingContainer').show();
    this.GetVideoListFromServer();
    this.GetMoreVideosFromServer();

  }, methods: {

    GetVideoListFromServer() {

      window.axios.get(this.api_link+'getAllVideos')
        .then((response) => {
          this.showVideos = response.data.list.data;
          this.image_link = response.data.path_video_cover;
          this.next_page = response.data.list.next_page_url;
          window.jQuery('#loading').hide();
          window.jQuery('#loadingContainer').hide();
        }).catch((error) => {
          console.log(error);
        this.$swal({
          title: this.$t('error_loading_video'),
          confirmButtonText: this.$t('close'),

        })
      });
    },

    GetMoreVideosFromServer() {
      if (this.next_page !== null) {
        // loader start
        window.jQuery('#loading').show();
        window.jQuery('#loadingContainer').show();
        window.jQuery('#moreLoading').hide();
        window.axios.get(this.next_page).then((response) => {
          let newvideo;
          for (newvideo in response.data.list.data) {
            this.showVideos.push(response.data.list.data[newvideo]);
          }
          this.next_page = response.data.list.next_page_url;
          window.jQuery('#loading').hide();
          window.jQuery('#loadingContainer').hide();
          if (this.next_page !== null) {
            window.jQuery('#moreLoading').show();
          }
        })
      }
    },
    showModalVideo(iframe) {
      window.jQuery('#listModal').modal('show');
      this.currentIframe = iframe;
    },
    closeIframe() {
      window.jQuery('iframe').attr('src', window.jQuery('iframe').attr('src'));
    }
  }

}
</script>
