import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {Routes} from './Routes'
//import VueAxios from 'vue-axios'
//import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(VueCookies)
Vue.use(require('vue-cookies'))

Vue.use(VueRouter)
import axios from 'axios'
import VueAxios from 'vue-axios'
window.axios = require('axios');
Vue.use(VueAxios, axios)



//Vue.use(VueAxios, axios)
// Vue.use(axios)

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
import {i18n} from './lang/lang'


Vue.config.productionTip = false

export const router = new VueRouter({
    routes: Routes,
    mode: 'history'
});


new Vue({
    el: '#app',
    created() {
        let auth = this.$cookies.get('auth');
        if (auth != null) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth;
        }
    },
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')



