<template>
  <div class="content pt-5">
    <div class="container-xl">

      <div class="row justify-content-center">
        <div class="col-12 col-sm-3 align-self-center">
          <h5 src="/img/logo.png" alt="logo" class="mx-auto my-3 d-block text-center" >
            {{$t('profile_update')}}
            </h5>
            <form id="register-form" method="post">
              <div class="form-group" hidden>
                <input type="file" class="form-control profile-img-input custom-file-input" id="file">
              </div>
              <div class="form-group">
                <input type="text" class="form-control" id="username" aria-describedby="usernameHelp" v-model="user.name" :placeholder="$t('username')">

              </div>
              <div class="form-group">
                <label for="email" hidden>Email address</label>
                <input type="email" class="form-control" id="email" aria-describedby="emailHelp" v-model="user.email" :placeholder="$t('phone')">

              </div>
              <div class="form-group">
                <label for="countrySelect" hidden>Example select</label>
                <select class="form-control text-summersky" id="countrySelect" v-model="user.country">

                  <option value="0" selected>{{$t('country')}}</option>
                  <option value="ir" >{{$t('iran')}}</option>
                  <option value="tr">{{$t('turkish')}}</option>
                  <option value="am">{{$t('armenia')}}</option>
                  <option value="ge">{{$t('georgia')}}</option>
                </select>
              </div>
              <div class="form-group">
                <div class="alert alert-info small">
                  <strong> {{$t('message')}} </strong> {{$t('note_for_edit_password')}}
                </div>
                <input type="text" class="form-control" id="password" :placeholder="$t('password')" v-model="this.password">
              </div>
              <button   type="button" class="btn bg-summersky text-white w-100" @click="UpdateProfileInServer">{{$t('send')}}</button>
            </form>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "profile",
  data(){
    return{
      usercookie : {},
      user : {},
      user_id : '',
       password : '',
    }
  }, created() {
  this.GetUserProfileFromServer();
  },methods:{
    GetUserProfileFromServer(){
      this.userCookie = this.$cookies.get('user');
      this.user_id = this.userCookie.id;

      window.axios.get('https://panel.raytalhaq.app/api/userInfo/' + this.user_id).
      then((response) =>
      {
            this.user = response.data;
      })
    },UpdateProfileInServer(){
      const  id  = this.user_id;
      const name = this.user.name;
      const email = this.user.email;
      const password = this.password;
      const country = this.user.country;

      let formData = new FormData();
      formData.append('name', name);
      formData.append('id', id);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('country', country);

      window.axios.post('https://panel.raytalhaq.app/api/userEdit' ,formData).then(() =>{
        this.$swal({
          title: this.$t('success_update_profile'),
          confirmButtonText: this.$t('close'),
          timer: 3000,
        });
      }).catch(() =>{
        this.$swal({
          title: this.$t('warning_update_profile'),
          confirmButtonText: this.$t('close'),
          timer: 3000,
        })
        })
    }
  }
}
</script>

<style scoped>

</style>
