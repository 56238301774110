import Index from "./Pages/Index/Index"
import FavoritesList from "./Pages/Favorites/FavoritesList";
import ComentsList from "./Pages/Comments/ComentsList";
import AboutUs from "./Pages/AboutUs/AboutUs";
import LogIn from "./Pages/LogIn/LogIn";
import Register from "./Pages/Register/Register";
import VideoList from "./Pages/Video/VideoList";
import productShow from "./Pages/Products/productShow";
import profile from "./Pages/Profile/profile";


 export const Routes = [
  {
      path: '/',
      name: 'Index',
      component: Index
  },
  {
      path: '/VideoList',
      name:'productList',
      component: VideoList
},{
     path: '/favoritesList',
     name:'favoritesList',
     component: FavoritesList
   },
   {
     path: '/commentsList',
     name:'commentsList',
     component: ComentsList
   },{
     path: '/AboutUs',
     name:'AboutUs',
     component: AboutUs
   },
   {
     path: '/LogIn',
     name:'LogIn',
     component: LogIn
   },{
     path: '/Register',
     name:'Register',
     component: Register
   },{
     path: '/book/:id',
     name:'productShow',
     component: productShow
   },{
     path: '/profile',
     name:'profile',
     component: profile
   },{
     path: '/bookmarks',
     name:'bookmark',
     component: FavoritesList
   }

]
