import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);


const messages = {

    'ar': {
        index: ' الرئيسية الصفحة',
        videos_list: ' لیست ویدیو ها',
        watch_video: 'قائمة الفيديو',
        favourites: 'المفضلة',
        comments: 'انشر تعليق',
        about_us: 'عنا',
        login: 'تسجيل الدخول',
        register: 'تسجيل',
        profile_update: 'تحرير المعلومات',
        logout: 'خروج',
        category: 'الاقسام',
        all: 'الكل',
        language: 'لغة',
        persian: 'فارسی',
        arabic: 'عربی',
        sort: ' ترتيب',
        desc: 'نزولی',
        Asce: 'صعودی',
        contact_us: 'اتصل بنا',
        follow_us: 'تابعنا',
        share: 'مشاركة',
        video: 'الفيديو',
        comment_text: 'نص التعليق',
        send_comment: 'ارسال تعليق',
        write_comment: 'أريد التعليق',
        iran: 'ایران',
        turkish: 'ترکیا',
        georgia: 'جورجیا',
        armenia: 'أرمينيا',
        username: 'اسم المستخدم',
        password: 'كلمة المرور',
        email: 'ایمیل',
        your_account: 'هل لديك حساب؟',
        not_found_user: 'المستخدم غير موجود',
        false_info: 'المعلومات التي تم إدخالها غير صحيحة',
        success_register: 'تم التسجيل بنجاح',
        success_update_profile: 'تم تحرير المعلومات بنجاح',
        warning_update_profile: 'خطأ في المعلومات',
        success_login: 'تم تسجيل الدخول بنجاح',
        warning_register: 'خطأ في التسجيل ، يرجى المحاولة مرة أخرى',
        exist_mobile: 'الرقم مكرر',
        warning_phone_or_usename: 'خطأ في الرقم او اسم المستخدم',
        error_loading_video: 'تحقق من الإنترنت',
        send: 'تسجيل',
        country: 'الدولة',
        phone: 'موبايل',
        message: 'الرسالة : ',
        bookmark: 'ظاهر',
        Un_bookmark: 'مخفي',
        seasons: 'الفصول',
        summery: 'ملخص',
        time: 'الوقت',
        attributes: 'المواصفات',
        minute: 'الدقائق',
        page: 'الصفحة',
        season: 'فصل',
        Mb: 'ميغا بايت',
        close: 'مغلق',
        success_comment: 'تم إرسال تعليقك بنجاح',
        error_comment: ' خطأ في التعليق ',
        loading: 'تحميل المعلومات',
        downloadPdf_text: 'تنزيل نسخة PDF من الكتاب',
        downloadBook_text: 'تنزيل الكتاب',
        showVideo_text: 'شاهد الفيديو',
        more: 'المزيد',
        file_size: 'الحجم الإجمالي للملف',
        one_file_size: 'الحجم الملف',
        created_at: ' تاريخ النشر',
        no_book_in_category: 'لا يوجد كتاب في الفئة المختارة.',
        browser_does_not_support_video: 'متصفحك لا يدعم الفيديو ذي الصلة',
        note_for_edit_password: ' في حال تغيير كلمة المرور ، املأ الحقل بكلمة المرور الجديدة. أو اتركه فارغًا',
        audio_book: 'كتاب صوتي',
        video_book: 'کتاب الصم',
        pdf_book: 'کتاب بی دی اف (pdf)',
        select_lang: 'اختر لغة الكتاب',
        fill_info: ' أدخل المعلومات أولاً',
        audio: 'صوت',
        play_audio: 'تشغيل الصوت',
        author: 'المؤلف ',
        speaker: 'المنفذ',
        date: 'تاریخ النشر'
    },
    'fa': {
        index: 'صفحه اصلی',
        videos_list: ' لیست ویدیو ها',
        watch_video: 'مشاهده ویدیو',
        favourites: 'مورد علاقه ها',
        comments: 'ارسال نظر',
        about_us: 'درباره ما',
        login: 'ورود',
        register: 'ثبت نام',
        profile_update: 'ویرایش اطلاعات',
        logout: 'خروج',
        category: 'دسته بندی',
        all: 'همه',
        language: 'زبان',
        persian: 'فارسی',
        arabic: 'عربی',
        sort: ' مرتب سازی',
        desc: 'نزولی',
        Asce: 'صعودی',
        contact_us: 'ارتباط با ما',
        follow_us: 'دنبال کردن ما',
        share: 'اشتراک گذاری',
        video: 'ویدیو',
        comment_text: 'متن نظر',
        send_comment: 'ارسال نظر',
        write_comment: 'می خواهم نظر بدهم',
        iran: "ایران",
        turkish: "ترکیه",
        georgia: "گرجستان",
        armenia: "ارمنستان",
        username: 'نام کاربر',
        password: 'گذرواژه',
        email: 'ایمیل',
        your_account: 'حساب کاربری دارید؟',
        not_found_user: 'کاربر یافت نشد',
        false_info: 'اطلاعات وارد شده صحیح نمی باشد',
        success_register: 'ثبت نام  با موفقیت انجام شد',
        success_update_profile: 'ویرایش اطلاعات  با موفقیت انجام شد',
        warning_update_profile: 'ویرایش اطلاعات با خطا مواجه شد',
        success_login: 'ورود  با موفقیت انجام شد',
        warning_register: 'ثبت نام  با خطا مواجه شد لطفا مجدد تلاش کنید',
        exist_mobile: 'شماره همراه تکراری است',
        warning_phone_or_usename: 'شماره همراه یا نام کاربری اشتباه است',
        error_loading_video: 'اتصال به اینترنت چک شود',
        send: 'ثبت',
        country: 'کشور',
        phone: 'شماره همراه',
        message: 'پیغام : ',
        bookmark: 'نشان شده',
        Un_bookmark: 'نشان نشده',
        seasons: 'فصل ها',
        summery: "خلاصه",
        time: 'زمان',
        attributes: 'مشخصات',
        minute: 'دقیقه',
        page: 'صفحه',
        season: 'فصل',
        Mb: 'مگابایت',
        close: 'بستن',
        success_comment: 'نظر شما با موفقیت ثبت شد',
        error_comment: ' ثبت نظر شما با خطا مواجه شد ',
        loading: 'در حال بارگیری اطلاعات',
        downloadPdf_text: 'دانلود نسخه پی دی اف کتاب',
        downloadBook_text: 'دانلود کتاب',
        showVideo_text: 'مشاهده ویدیو',
        more: 'بیشتر',
        file_size: 'حجم کلی فایل ها',
        one_file_size: 'حجم فایل',
        created_at: ' تاریخ نشر',
        no_book_in_category: 'در دسته بندی انتخاب شده کتابی وجود ندارد.',
        browser_does_not_support_video: 'مرورگر شما ویدیو مربوطه را پشتیبانی نمی کند',
        note_for_edit_password: ' در صورت تغییر گذرواژه فیلد مربوطه را با گذرواژه ی جدید پر کنید. در غیر اینصورت آن را خالی رها کنید',
        audio_book: 'كتاب صوتي',
        video_book: 'کتاب ناشنوایان',
        pdf_book: 'کتاب پی دی اف (pdf)',
        select_lang: 'زبان کتاب را انتخاب نمایید',
        fill_info: ' ابتدا اطلاعات را وارد نمایید',
        audio: 'صوت',
        play_audio: 'پخش صوت',
        author: 'نویسنده',
        speaker: 'مجری',
        date: 'تاریخ نشر'
    }

};
export const i18n = new VueI18n({locale: 'ar', messages});
