<template>
  <div class="content pt-5">
    <div class="container-xl">

      <div class="row justify-content-center">
        <div class="col-12 col-sm-3 align-self-center">
          <img src="/img/logo.png" alt="logo" class="mx-auto my-3 d-block" />
          <form id="register-form">
            <div class="form-group">
              <input type="text" class="form-control" id="username" :placeholder="$t('username')" aria-describedby="usernameHelp" v-model="name" required>
              <small id="emailHelp" class="form-text text-muted" hidden>We'll never share your email with anyone else.</small>
            </div>
            <div class="form-group">
              <label for="email" hidden>Email address</label>
              <input type="number" class="form-control" id="email" :placeholder="$t('phone')" v-model="email"  aria-describedby="emailHelp" required>
            </div>
            <div class="form-group">
              <label for="countrySelect" hidden>Example select</label>
              <select class="form-control text-summersky" v-model="country" id="countrySelect" required>
                <option value="ir" selected>{{$t('iran')}}</option>
                <option value="tr">{{$t('turkish')}}</option>
                <option value="am">{{$t('armenia')}}</option>
                <option value="ge">{{$t('georgia')}}</option>
              </select>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" id="password" v-model="password" :placeholder="$t('password')" required>
            </div>
            <button type="button" class="btn bg-summersky text-white w-100" @click="SetDataRegisterToServer">{{$t('register')}} </button>
          </form>
          <p class="my-4 text-center">
           {{$t('your_account')}}
            <router-link to="/LogIn"><a  class="text-summersky" title="">
              {{$t('login')}}
            </a></router-link>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data(){
    return{
       name : '',
       password : '',
       email: '',
       country : 'ir',
    }
  },
  created()
  {

  },methods:{
     SetDataRegisterToServer(){
       if (this.name.trim() != '' && this.password.trim() !='' && this.email.trim() !='' && this.country.trim() !='') {
         
         let name = this.name ;
         let password = this.password ;
         let email = this.email ;
         let country = this.country;
         
         let formData = new FormData();
         formData.append('name', name);
         formData.append('password', password);
         formData.append('email', email);
         formData.append('country', country);
         window.axios.post('https://panel.raytalhaq.app/api/register', formData, {
           headers: {
             'Content-Type': 'multipart/form-data'
           }
         }).then(response => {
           console.log(response)
           this.LogInUserFromServer();
  
         }).catch(err => {
           console.log(err)
           this.$swal({
             title: this.$t('fill_info'),
             confirmButtonText: this.$t('close'),
             timer: 4000,
           })
         })
       }
     else 
       this.$swal({
         title: this.$t('fill_info'),
         confirmButtonText: this.$t('close'),
         timer: 3000,
       })
     },LogInUserFromServer() {

      let email = this.email;
      let password = this.password;

      let formData = new FormData();
      formData.append('password', password);
      formData.append('email', email);
      window.axios.post('https://panel.raytalhaq.app/api/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {

        window.$cookies.set('auth', response.data['access_token'], '130d');
        window.$cookies.set('user', response.data.user, '130d');
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data['access_token'];
        this.$swal({
          title: this.$t('success_register'),
          confirmButtonText: this.$t('close'),
          timer: 3000,
        })
        this.$router.push({path: '/'});
      }).catch(err => {
        console.log(err)
        this.$swal({
          title: this.$t('warning_register'),
          confirmButtonText: this.$t('close'),
          timer: 3000,
        })
      })

    }
  }
}
</script>

<style scoped>

</style>
