<template>
  <div id="app">

    <app-header></app-header>

    <router-view></router-view>

    <app-footer></app-footer>

  </div>
</template>

<script>

import AppHeader from './Pages/Shared/Header';
import AppFooter from './Pages/Shared/Footer';

export default
{
  name: 'app',
  components:
      {
        AppHeader: AppHeader,
        AppFooter: AppFooter,
      }

}
</script>


