<template>
  <div class="content pt-5">
    <div class="container-xl">

      <div class="row justify-content-center">
        <div class="col-12 col-sm-3 align-self-center">
          <img src="/img/logo.png" alt="logo" class="mx-auto my-3 d-block" />
          <form id="register-form">
            <div class="form-group">
              <input type="text" class="form-control" id="username" :placeholder= " $t('phone')" aria-describedby="usernameHelp" v-model="email">
              <small id="emailHelp" class="form-text text-muted" hidden>We'll never share your email with anyone else.</small>
            </div>
            <div class="form-group">
              <label hidden>Password</label>
              <input type="password" class="form-control" id="password" :placeholder="$t('password')" v-model="password">
            </div>
            <button  type="button" class="btn bg-summersky text-white w-100" @click=" LogInUserFromServer">{{$t('login')}}</button>
          </form>
          <p class="my-4 text-center">
            <router-link to="/Register"><a  class="text-summersky" title="">
              {{$t('register')}}
            </a></router-link>
          </p>
          <hr>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogIn",
  data(){
    return{
      email: '',
      password :'',
    }
  },
  created()
  {

  },methods:{
    LogInUserFromServer(){

      let email = this.email;
      let password = this.password;

      let formData = new FormData();
      formData.append('password', password);
      formData.append('email', email);
      window.axios.post('https://panel.raytalhaq.app/api/login',formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {

        window.$cookies.set('auth', response.data['access_token'], '130d');
        window.$cookies.set('user', response.data.user, '130d');
        console.log(response.data.user);
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data['access_token'];
        this.$swal({
          title: this.$t('success_login'),
          confirmButtonText: this.$t('close'),
          timer: 2000,
        })
        this.$router.push({path: '/'});

      }).catch(err =>{
        console.log(err)
        this.$swal({
          title: this.$t('warning_phone_or_usename'),
          confirmButtonText: this.$t('close'),
          timer: 4000,
        })

      })
    }
  }
}
</script>

<style scoped>

</style>
