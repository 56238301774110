<template>
  <div class="content">
    <div class="container-xl">
      <div class="products-wrapper">
        <div class="row">
          <div v-for="book in books" :key="book.id" class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="product-wrapper">
              <div class="product-box">
                <router-link :to="{name: 'productShow', params: { id: book.id }}">
                  <a href="#">
                    <div class="product-image-wrapper">
                      <img :src="image_link +'/'+ book.id +'/'+ book.image" style="height: 200px" alt="raytalhaq" class="img-fluid"/>
                    </div>
                    <div class="clearfix"></div>
                    <p v-if="$i18n.locale === 'ar'" class="book-name"><strong>{{ book.ar_name }}</strong></p>
                    <p v-if="$i18n.locale === 'fa'" class="book-name"><strong>{{ book.fa_name }}</strong></p>
                    <p class="writer-name">{{ book.author }}</p>
                  </a>
                </router-link>

                <div class="row link-box">
                  <a v-if="book.ar_pdf !== 0 || book.fa_pdf !== 0" class="col link-book">
                    <span v-if="book.fa_pdf == 1 && book.ar_pdf == 0">
                      <i class="las la-book">Fa</i>
                    </span>
                    <span v-if="book.fa_pdf == 0 && book.ar_pdf == 1">
                        <i class="las la-book">Ar</i>
                    </span>
                    <span v-if="book.fa_pdf == 1 && book.ar_pdf == 1">
                        <i class="las la-book">Ar, Fa</i>
                    </span>
                  </a>

                  <a href="#" id="mp4" class="col link-video" v-if="book.ar_mp4 !== 0 || book.fa_mp4 !== 0">
                    <span v-if="book.fa_mp4 == 1 && book.ar_mp4 == 0">
                      <i class="lar la-play-circle">Fa</i>
                    </span>
                    <span v-if="book.fa_mp4 == 0 && book.ar_mp4 == 1">
                        <i class="lar la-play-circle">Ar</i>
                    </span>
                    <span v-if="book.fa_mp4 == 1 && book.ar_mp4 == 1">
                        <i class="lar la-play-circle">Ar, Fa</i>
                    </span>
                  </a>

                  <a href="#" class="col link-sound" v-if="book.ar_mp3 !== 0 || book.fa_mp3 !== 0">
                    <span v-if="book.fa_mp3 == 1 && book.ar_mp3 == 0">
                      <i class="las la-headphones">Fa</i>
                    </span>
                    <span v-if="book.fa_mp3 == 0 && book.ar_mp3 == 1">
                        <i class="las la-headphones">Ar</i>
                    </span>
                    <span v-if="book.fa_mp3 == 1 && book.ar_mp3 == 1">
                        <i class="las la-headphones">Ar,Fa</i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 400px ; width: 100%;margin-top: -200px" id="loadingContainer">
          <vue-element-loading id="loading" background-color="transparent" opacity="80%" spinner="ring"
                               :text="$t('loading')" color="black" size="100" style="margin-top: 20%"
                               v-model="fullPage"/>
        </div>
        <a v-if="this.next_page != null" id="moreLoading" @click.prevent="GetMoreBooksFromServer"
           style="margin-top: 1% ; justify-self: left" class="btn btn-outline-secondary">{{ $t('more') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import VueElementLoading from 'vue-element-loading'

export default {
  name: "FavoritList",
  data() {
    return {
      books: {},
      no_bookmark: false,
      model: true,
      next_page: null,
      fullPage: '',
      api_link: "https://panel.raytalhaq.app/api/bookmark", //http://192.168.1.66/products/laravelcork/public/api/bookmark
      image_link: '',
    }
  }, components: {
    VueElementLoading
  }, mounted() {
    window.jQuery('#loading').show();
    window.jQuery('#loadingContainer').show();
    this.GetBookmarkListFromServer()
  },
  methods: {
    GetBookmarkListFromServer() {
      let parchamhagh_bookmark = this.$cookies.get("parchamhagh_bookmark");
      if (parchamhagh_bookmark === null) {
        window.jQuery('#loadingContainer').hide();
      } else {
        parchamhagh_bookmark = JSON.parse(parchamhagh_bookmark);
        if (parchamhagh_bookmark.length === 0)
          window.jQuery('#loadingContainer').hide();
      }

      if (parchamhagh_bookmark !== null && parchamhagh_bookmark.length > 0) {
        let formdata = new FormData();
        parchamhagh_bookmark.forEach((item) => {
          formdata.append('bookIds[]', item);
        });
        window.axios.post(this.api_link, formdata).then((response) => {
          this.books = response.data.list.data;
          this.image_link = response.data.book_img_path;
          this.next_page = response.data.list.next_page_url;
          window.jQuery('#loading').hide();
        });
      }
    },
    GetMoreBooksFromServer() {
      if (this.next_page !== null) {
        // loader start
        window.jQuery('#loading').show();
        window.jQuery('#loadingContainer').show();
        window.jQuery('#moreLoading').hide();
        let parchamhagh_bookmark = this.$cookies.get("parchamhagh_bookmark");
        if (parchamhagh_bookmark === null)
          this.no_bookmark = true;
        else {
          parchamhagh_bookmark = JSON.parse(parchamhagh_bookmark);
          if (parchamhagh_bookmark.length === 0)
            window.jQuery('#loadingContainer').hide();
        }

        if (parchamhagh_bookmark !== null && parchamhagh_bookmark.length > 0) {
          let formdata = new FormData();
          parchamhagh_bookmark.forEach((item) => {
            formdata.append('bookIds[]', item);
          });

          window.axios.post(this.next_page, formdata).then((response) => {
            let newbook;
            for (newbook in response.data.list.data) {
              this.books.push(response.data.list.data[newbook]);
            }
            this.next_page = response.data.list.next_page_url;
            window.jQuery('#loading').hide();
            window.jQuery('#loadingContainer').hide();
            if (this.next_page !== null) {
              window.jQuery('#moreLoading').hide();
            }
          });
        }
      }
    }
  }
}
</script>
