<template>

  <div class="content">
    <div class="container-xl">
      <form class="row filter-box">

        <div class="form-group col-12 col-sm-3">
          <label class="filter-select" for="sortType">{{ $t('sort') }}</label>
          <select class="form-control" id="sortType" @change="onChangesortType">
            <option value="all">{{ $t('all') }}</option>
            <option value="pdf">{{ $t('pdf_book') }}</option>
            <option value="mp3">{{ $t('audio_book') }}</option>
            <option value="mp4">{{ $t('video_book') }}</option>
          </select>
        </div>

        <div class="form-group col-12 col-sm-3">
          <label class="filter-select" for="sortLang">{{ $t('language') }}</label>
          <select class="form-control" id="sortLang" @change="onChangeSortLang">
            <option value="all">{{ $t('all') }}</option>
            <option value="fa">{{ $t('persian') }}</option>
            <option value="ar">{{ $t('arabic') }}</option>
          </select>
        </div>

        <div class="form-group col-12 col-sm-3">
          <label class="filter-select">{{ $t('category') }}</label>
          <select class="form-control" id="sortCat" @change="onChangeSortCat">
            <option value="all" selected>{{ $t('all') }}</option>
            <option v-for="category in categories" v-bind:key="category.id" :value="category.id">{{
                category.fa_name
              }}
            </option>
          </select>
        </div>
      </form>
      <div class="products-wrapper">
        <div class="row">
          <div class="alert alert-info col-sm-10" role="alert" id="alertNull" style="display: none">
            <p align="center" style="margin: 1% 1% 1% 1% ; border-bottom-style: dot-dash ; border: lightslategray">
              {{ $t('no_book_in_category') }}
            </p>
          </div>

          <div v-for="book in books" v-bind:key="book.id" class="col-12 col-sm-6 col-md-4 col-lg-3">
            <div class="product-wrapper">
              <div class="product-box">
                <router-link :to="{name: 'productShow', params: { id: book.id }}">
                  <a href="#">
                    <div class="product-image-wrapper">
                      <img :src="image_link +'/'+ book.id +'/'+ book.image" style="height: 200px" alt="راية الحق" class="img-fluid"/>
                    </div>
                    <div class="clearfix"></div>
                    <p v-if="$i18n.locale === 'ar'" class="book-name"><strong>{{ book.ar_name }}</strong></p>
                    <p v-if="$i18n.locale === 'fa'" class="book-name"><strong>{{ book.fa_name }}</strong></p>
                    <p class="writer-name">{{ book.author }}</p>
                  </a>
                </router-link>

                <div class="row link-box">
                  <a v-if="book.ar_pdf !== 0 || book.fa_pdf !== 0" class="col link-book">
                      <span v-if="book.fa_pdf == 1 && book.ar_pdf == 0">
                        <i class="las la-book">Fa</i>
                      </span>
                    <span v-if="book.fa_pdf == 0 && book.ar_pdf == 1">
                          <i class="las la-book">Ar</i>
                      </span>
                    <span v-if="book.fa_pdf == 1 && book.ar_pdf == 1">
                          <i class="las la-book">Ar, Fa</i>
                      </span>
                  </a>

                  <a href="#" id="mp4" class="col link-video" v-if="book.ar_mp4 !== 0 || book.fa_mp4 !== 0">
                      <span v-if="book.fa_mp4 == 1 && book.ar_mp4 == 0">
                        <i class="lar la-play-circle">Fa</i>
                      </span>
                    <span v-if="book.fa_mp4 == 0 && book.ar_mp4 == 1">
                          <i class="lar la-play-circle">Ar</i>
                      </span>
                    <span v-if="book.fa_mp4 == 1 && book.ar_mp4 == 1">
                          <i class="lar la-play-circle">Ar, Fa</i>
                      </span>
                  </a>

                  <a href="#" class="col link-sound" v-if="book.ar_mp3 !== 0 || book.fa_mp3 !== 0">
                      <span v-if="book.fa_mp3 == 1 && book.ar_mp3 == 0">
                        <i class="las la-headphones">Fa</i>
                      </span>
                    <span v-if="book.fa_mp3 == 0 && book.ar_mp3 == 1">
                          <i class="las la-headphones">Ar</i>
                      </span>
                    <span v-if="book.fa_mp3 == 1 && book.ar_mp3 == 1">
                          <i class="las la-headphones">Ar, Fa</i>
                      </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 400px ; width: 100%;margin-top: -200px" id="loadingContainer">
          <vue-element-loading id="loading" background-color="transparent" opacity="80%" spinner="ring"
                               :text="$t('loading')" color="black" size="100" style="margin-top: 20%"
                               v-model="fullPage"/>
        </div>
        <a v-if="this.next_page != null" id="moreLoading" @click.prevent="GetMoreBooksFromServer"
           style="margin-top: 1% ; justify-self: left" class="btn btn-outline-secondary">{{ $t('more') }}</a>

      </div>
    </div>
  </div>

</template>


<script>
import VueElementLoading from 'vue-element-loading'
//import {apiUrl} from 'vue.config';
export default {
  name: "Index",
  data() {
    return {
      books: {},
      image_link: '',
      next_page: null,
      isLoading: false,
      fullPage: true,
      sortCat: 'all',
      sortLang: 'all',
      sortType: 'all',
      sortDate: 'desc',
      categories: [],
      testVal: null,
      api_link: 'https://panel.raytalhaq.app/api/'
    }
  }, components: {

    VueElementLoading

  }, mounted() {
    window.jQuery('#loading').show();
    window.jQuery('#loadingContainer').show();
    this.GetCategoriesListFromServer();
    this.GetMoreBooksFromServer();
  },
  methods: {
    GetCategoriesListFromServer() {
      window.axios.get(this.api_link + 'categories', {
        headers: {
          'Content-type': 'application/json'
        }
      })
          .then((response) => {
            this.categories = response.data;
            this.GetBooksListFromServer();
          }).catch((error) => {
        console.log(error);
        this.$swal({
          title: this.$t('error_loading_video'),
          confirmButtonText: this.$t('close'),
          timer: 2000,
        })
      });
    },
    onChangeSortCat() {
      this.sortCat = window.jQuery('#sortCat').val();
      this.GetBooksListFromServer();
    },
    onChangeSortLang() {
      this.sortLang = window.jQuery('#sortLang').val();
      this.GetBooksListFromServer();
    },
    onChangesortType() {
      this.sortType = window.jQuery('#sortType').val();
      this.GetBooksListFromServer();
    },
    GetBooksListFromServer() {
      let link = this.api_link + 'books' + '/' + this.sortCat + '/' + this.sortLang + '/' + this.sortDate + '/' + this.sortType;
      window.axios.get(link, {
        headers: {
          'Content-type': 'application/json'
        }
      }).then((response) => {
            this.books = response.data.list.data;
            this.image_link = response.data.book_img_path;
            this.next_page = response.data.list.next_page_url;
            window.jQuery('#loading').hide();
            window.jQuery('#loadingContainer').hide();
            if (this.books.length == 0)
              window.jQuery("#alertNull").show();
            else
              window.jQuery("#alertNull").hide();
          })
    },
    GetMoreBooksFromServer() {
      if (this.next_page !== null) {
        // loader start
        window.jQuery('#loading').show();
        window.jQuery('#loadingContainer').show();
        window.jQuery('#moreLoading').hide();
        window.axios.get(this.next_page, {
          headers: {
            'Content-type': 'application/json'
          }
        }).then((response) => {
          let newbook;
          for (newbook in response.data.list.data) {
            this.books.push(response.data.list.data[newbook]);
          }
          this.next_page = response.data.list.next_page_url;
          window.jQuery('#loading').hide();
          window.jQuery('#loadingContainer').hide();
          if (this.next_page !== null) {
            window.jQuery('#moreLoading').show();
          }
        })
      }
    }
  }
}
</script>

<style scoped/>
